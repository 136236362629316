import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import Select, {ClassNamesConfig, SelectComponentsConfig} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import {CustomReactMultiSelect} from '../../Custom/CustomReactSelect'
import {FormError} from '../FormError'
import {FormMultiSelectProps, MultiSelectOption} from './Multiselect.types'
import GIcon from '../../../Icon/GIcon'
import clsx from 'clsx'

export const FormMultiSelect: FC<FormMultiSelectProps> = ({
  options,
  placeholder,
  error,
  touched,
  defaultValue,
  className,
  changedValue,
  disabled,
  selectedValue,
  asyncSelect,
  loadOptions,
  allowSelectAll = false,
  allowSelectAllPlaceholder = 'All Items',
  cacheUniqs,
  getOptionValue,
  menuIsOpen,
  isClearable = false,
  showDropdownIndicator = true,
  backspaceRemovesValue = true,
  tabSelectsValue = true,
  controlShouldRenderValue = true,
}) => {
  const [val, setVal] = useState<MultiSelectOption[]>([])
  const [asyncVal, setAsyncVal] = useState<MultiSelectOption[]>([])

  const selectAllOption = useMemo(
    () => ({
      value: '<SELECT_ALL>',
      label: allowSelectAllPlaceholder,
    }),
    [allowSelectAllPlaceholder]
  )

  const isSelectAllSelected = useCallback(() => {
    if (!asyncSelect) return val?.length === options.length
    if (asyncSelect) return val[0]?.value === selectAllOption.value
  }, [asyncSelect, val, options, selectAllOption.value])

  const isOptionSelected = useCallback(
    (option: any) => val.some(({value}) => value === option.value) || isSelectAllSelected(),
    [isSelectAllSelected, val]
  )

  const getOptions = useCallback(() => {
    if (allowSelectAll) return [selectAllOption, ...options]
    else return options
  }, [allowSelectAll, selectAllOption, options])

  const getAsyncOptions = useCallback(
    async (search: string, loadedOptions: any) => {
      const previousOptions: Array<any> = allowSelectAll ? loadedOptions.slice(1) : loadedOptions
      const payload = await loadOptions(search, previousOptions)

      const newSelectAll = {
        options: [] as MultiSelectOption[],
        hasMore: payload.hasMore,
      }

      if (allowSelectAll && loadedOptions.length === 0 && payload.options.length > 0) {
        newSelectAll.options.push(selectAllOption)
      }

      newSelectAll.options.push(...payload.options)

      if (!cacheUniqs) {
        setAsyncVal((prev) => [...prev, ...payload.options])
      }

      if (cacheUniqs) {
        setAsyncVal(() => [...previousOptions, ...payload.options])
      }

      return newSelectAll
    },
    [allowSelectAll, selectAllOption, loadOptions, cacheUniqs]
  )

  const getValue = useCallback(() => {
    if (allowSelectAll) {
      return isSelectAllSelected() ? [selectAllOption] : val
    } else {
      return val
    }
  }, [allowSelectAll, isSelectAllSelected, selectAllOption, val])

  const selectChanges = useCallback(
    (v: MultiSelectOption[], actionMeta: any) => {
      const {action, option, removedValue} = actionMeta

      if (changedValue) {
        if (!asyncSelect) {
          if (action === 'select-option' && option.value === selectAllOption.value) {
            changedValue(options, actionMeta)
            setVal(options)
          } else if (
            (action === 'deselect-option' && option.value === selectAllOption.value) ||
            (action === 'remove-value' && removedValue.value === selectAllOption.value)
          ) {
            changedValue([], actionMeta)
            setVal([])
          } else if (action === 'deselect-option' && isSelectAllSelected()) {
            changedValue(
              options.filter(({value}) => value !== option.value),
              actionMeta
            )
            setVal(options.filter(({value}) => value !== option.value))
          } else {
            changedValue(v || [], actionMeta)
            setVal(v)
          }
        }
      }
    },
    [changedValue, isSelectAllSelected, options, selectAllOption, asyncSelect]
  )

  const selectChangesAsync = useCallback(
    (v: MultiSelectOption[], actionMeta: any) => {
      const {action, option, removedValue} = actionMeta

      if (changedValue) {
        if (asyncSelect) {
          if (action === 'select-option' && option.value === selectAllOption.value) {
            changedValue([selectAllOption], actionMeta)
            setVal([selectAllOption])
          } else if (
            (action === 'deselect-option' && option.value === selectAllOption.value) ||
            (action === 'remove-value' && removedValue.value === selectAllOption.value)
          ) {
            changedValue([], actionMeta)
            setVal([])
          } else if (action === 'deselect-option' && isSelectAllSelected()) {
            changedValue(
              asyncVal.filter(({value}) => value !== option.value),
              actionMeta
            )
            setVal(asyncVal.filter(({value}) => value !== option.value))
          } else {
            changedValue(v || [], actionMeta)
            setVal(v)
          }
        }
      }
    },
    [changedValue, isSelectAllSelected, selectAllOption, asyncVal, asyncSelect]
  )

  const onChange = useCallback(
    (v: MultiSelectOption[], actionMeta: any) => {
      selectChanges(v, actionMeta)
      selectChangesAsync(v, actionMeta)
    },
    [selectChanges, selectChangesAsync]
  )

  useEffect(() => {
    if (!asyncSelect) {
      if (selectedValue?.length === 0 || selectedValue === undefined) {
        setVal([])
      } else {
        setVal(selectedValue)
      }
    }
  }, [selectedValue, options, asyncSelect])

  useEffect(() => {
    if (asyncSelect && defaultValue) {
      setVal(defaultValue)
    }
  }, [defaultValue, asyncSelect])

  const hardClassName: ClassNamesConfig<any, boolean, any> = {
    placeholder: () => 'text-neutral-60 font-medium',
    control: () =>
      `text-fs-9 rounded-lg font-medium text-neutral-100 ${
        touched && error ? 'border-danger' : 'border-solid border-[#D0D5DD]'
      } ${disabled && 'bg-gray-50'}`,
    valueContainer: (state) => `min-h-0 px-4 ${state.isMulti ? 'py-0 h-11 h-auto' : 'py-3'}`,
    input: () => 'm-0 p-0',
    dropdownIndicator: (state) =>
      `transition-transform duration-150 
      ${state.selectProps.menuIsOpen ? 'rotate-180' : 'rotate-0'}
      ${!showDropdownIndicator && 'hidden'}
      `,
    indicatorsContainer: () => 'max-h-[44px]',
    option: (state) =>
      `text-fs-9 rounded px-2 py-3 cursor-pointer ${
        state.isFocused ? 'text-primary' : 'text-neutral-80'
      }`,
    menuList: () => 'p-3 border-none max-h-[300px]',
    menu: () => 'shadow-none drop-shadow-[0_0_50px_rgba(33,37,41,0.13)] ',
    menuPortal: () => 'z-[999999]',
  }

  return (
    <div data-testid='formselect-test' className={clsx('relative', className)}>
      {asyncSelect ? (
        <AsyncPaginate
          isOptionSelected={isOptionSelected as any}
          data-testid='formselect-test-value'
          defaultOptions
          cacheUniqs={cacheUniqs}
          placeholder={placeholder}
          onChange={onChange}
          loadOptions={getAsyncOptions}
          debounceTimeout={500}
          components={CustomReactMultiSelect as SelectComponentsConfig<any, boolean, any>}
          value={getValue()}
          defaultValue={defaultValue}
          isDisabled={disabled}
          controlShouldRenderValue={controlShouldRenderValue}
          menuIsOpen={menuIsOpen}
          isClearable={isClearable}
          styles={{
            multiValue: () => ({
              display: 'flex',
              minWidth: 0,
              backgroundColor: '#F2F4F7',
              borderRadius: 16,
              margin: 2,
              padding: 8,
              boxSizing: 'border-box',
            }),
            multiValueLabel: (base, props) => ({
              width: '100%',
              textAlign: 'center',
              fontSize: '12',
              color: '#475467',
            }),
            multiValueRemove: (base, state) => ({
              visibility: disabled ? 'hidden' : 'visible',
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#EFF6FF' : '',
            }),
          }}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isMulti={true}
          classNames={hardClassName}
          isSearchable
          getOptionValue={getOptionValue}
          menuPortalTarget={document.querySelector('body')}
          backspaceRemovesValue={backspaceRemovesValue}
          tabSelectsValue={tabSelectsValue}
        />
      ) : (
        <Select
          isOptionSelected={isOptionSelected as any}
          data-testid='formselect-test-value'
          components={CustomReactMultiSelect as SelectComponentsConfig<any, boolean, any>}
          options={getOptions()}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={getValue()}
          isDisabled={disabled}
          controlShouldRenderValue={controlShouldRenderValue}
          styles={{
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#EFF6FF' : '',
            }),
            multiValue: (base, state) => ({
              ...base,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#F2F4F7',
              borderRadius: '16px',
              color: '#475467',
              margin: '2px 2px', // Ensure there is some space between each selected option
              paddingTop: '2px',
              paddingRight: '10px',
              paddingBottom: '2px',
              paddingLeft: '10px',
              boxSizing: 'border-box',
            }),
          }}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isMulti={true}
          classNames={hardClassName}
          getOptionValue={getOptionValue}
          menuPortalTarget={document.querySelector('body')}
          menuIsOpen={menuIsOpen}
          isClearable={isClearable}
          backspaceRemovesValue={backspaceRemovesValue}
          tabSelectsValue={tabSelectsValue}
        />
      )}

      {touched && error && <GIcon icon='IconWarningDanger' className={clsx('cursor-pointer absolute w-5 h-5 top-3 right-11')} />}
      {touched && error && <FormError>{error}</FormError>}
    </div>
  )
}
