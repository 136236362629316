import {FC} from 'react'
import InlineSVG from 'react-inlinesvg'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'

export enum GIconCollection {
  IconAdd = '/media/icons/IconAdd.svg',
  IconArrowTop = '/media/icons/IconArrowTop.svg',
  IconBack = '/media/icons/IconBack.svg',
  IconBurger = '/media/icons/IconBurger.svg',
  IconCalendar = '/media/icons/IconCalendar.svg',
  IconCheck = '/media/icons/IconCheck.svg',
  IconCheckCircle = '/media/icons/IconCheckCircle.svg',
  IconCheckOutlineCircle = '/media/icons/IconCheckOutlineCircle.svg',
  IconChevronRight = '/media/icons/IconChevronRight.svg',
  IconClone = '/media/icons/IconClone.svg',
  IconClose = '/media/icons/IconClose.svg',
  IconCloseRound = '/media/icons/IconCloseRound.svg',
  IconDocumentDownload = '/media/icons/IconDocumentDownload.svg',
  IconDoubleChevronLeft = '/media/icons/IconDoubleChevronLeft.svg',
  IconDown = '/media/icons/IconDown.svg',
  IconDelete = '/media/icons/IconDelete.svg',
  IconDeleteWhite = '/media/icons/IconDeleteWhite.svg',
  IconEdit = '/media/icons/IconEdit.svg',
  IconEmot = '/media/icons/IconEmot.svg',
  IconEyeOn = '/media/icons/IconEyeOn.svg',
  IconEyeOff = '/media/icons/IconEyeOff.svg',
  IconEnvelope = '/media/icons/IconEnvelope.svg',
  IconExclamation = '/media/icons/IconExclamation.svg',
  IconFilter = '/media/icons/IconFilter.svg',
  IconFileCSV = '/media/icons/IconFileCSV.svg',
  IconImagePlaceHolder = '/media/icons/IconImagePlaceHolder.svg',
  IconInfo = '/media/icons/IconInfo.svg',
  IconLaptop = '/media/icons/IconLaptop.svg',
  IconLoading = '/media/icons/IconLoading.svg',
  IconMoon = '/media/icons/IconMoon.svg',
  IconPaginationRight = '/media/icons/IconPaginationRight.svg',
  IconPaginationLeft = '/media/icons/IconPaginationLeft.svg',
  IconPaperPlane = '/media/icons/IconPaperPlane.svg',
  IconRefresh = '/media/icons/IconRefresh.svg',
  IconSearch = '/media/icons/IconSearch.svg',
  IconSun = '/media/icons/IconSun.svg',
  IconUserFriends = '/media/icons/IconUserFriends.svg',
  IconUp = '/media/icons/IconUp.svg',
  IconUpDown = '/media/icons/IconUpDown.svg',
  IconUpload = '/media/icons/IconUpload.svg',
  IconSignOut = '/media/icons/IconSignOut.svg',
  IconView = '/media/icons/IconView.svg',
  IconWarning = '/media/icons/IconWarning.svg',
  IconWarningDanger = '/media/icons/IconWarningDanger.svg',
  IconWarningCircle = '/media/icons/IconWarningCircle.svg',
  IconUsers = '/media/icons/IconUsers.svg',
  IconLock = '/media/icons/IconLock.svg',
  IconAlertYellow = '/media/icons/IconAlertYellow.svg',
  IconLoadingMakan = '/media/icons/IconLoadingMakan.svg',
  IconCopyData = '/media/icons/IconCopyData.svg',
  IconExportCSV = '/media/icons/IconExportCSV.svg',
  IconQuestion = '/media/icons/IconQuestion.svg',
  IconCloud = '/media/icons/IconCloud.svg',
  IconZoomIn = '/media/icons/IconZoomIn.svg',
  IconZoomOut = '/media/icons/IconZoomOut.svg',
  IconClock = '/media/icons/IconClock.svg',
  IconUploadRed = '/media/icons/IconUploadRed.svg',
  IconActions = '/media/icons/IconActions.svg',
  IconCursorDrag = '/media/icons/IconCursorDrag.svg',
  IconCloseGray = '/media/icons/IconCloseGray.svg',
  IconChecklist = '/media/icons/IconChecklist.svg',
  IconChecklistDanger = '/media/icons/IconChecklistDanger.svg',
  IconDotTreeHorizontal = '/media/icons/IconDotTreeHorizontal.svg',
  IconPercent = '/media/icons/IconPercent.svg',
  Iconshape = '/media/icons/Iconshape.svg',
  IconMore = '/media/icons/IconMore.svg',
  IconRotateLeft = '/media/icons/IconRotateLeft.svg',
  IconStop = '/media/icons/IconStop.svg',
  IconCheckMinus = '/media/icons/IconCheckMinus.svg',
  IconStopSign = '/media/icons/IconStopSign.svg',
  IconSetting = '/media/icons/IconSetting.svg',
  IconDownload = '/media/icons/IconDownload.svg',
  IconRefreshAlt = '/media/icons/IconRefreshAlt.svg',
  IconAsk = '/media/icons/IconAsk.svg',
  IconCopy = '/media/icons/IconCopy.svg',
}

export type GIconProps = keyof typeof GIconCollection

type Props = {
  icon: GIconProps
  className?: string
  onClick?: () => void
}

const GIcon: FC<Props> = ({icon, className, onClick}) => {
  return (
    <InlineSVG src={toAbsoluteUrl(GIconCollection[icon])} className={className} onClick={onClick} />
  )
}

export default GIcon
