import {FC} from 'react'
import {GButton} from 'src/app/components/Libs'

const Error404: FC = () => {
  return (
    <div
      data-testid='error-404-page-test'
      className='flex items-center justify-center w-full  h-screen'
    >
      <div className='text-center'>
        <div className='mb-5 font-semibold text-primary text-fs-9'>404 page not found</div>
        <div className='mb-5 font-semibold text-fs-3'>We can’t find that page</div>
        <div className='text-fs-7 mb-14'>
          The page you are looking for doesn't exist or has been moved.
        </div>
        <div>
          <GButton href='/' size='large'>
            Go To Dashboard
          </GButton>
        </div>
      </div>
    </div>
  )
}

export default Error404
