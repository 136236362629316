import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosBranchMenuScreen = {
  POS_BRANCH_MENU: {
    PATH: '/master-data/branch-menu',
    TITLE: 'Branch Menu ',
    TITLE_ALT: 'Branch Menu',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_BRANCH_MENU_DETAIL: {
    PATH: '/master-data/branch-menu/detail/:id',
    TITLE: 'Detail Branch Menu',
    TITLE_ALT: 'Detail Branch Menu',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_BRANCH_MENU_EDIT: {
    PATH: '/master-data/branch-menu/edit/:id',
    TITLE: 'Edit Branch Menu ',
    TITLE_ALT: 'Edit Branch Menu',
    breadcrumbs: defaultBreadcrumbs,
  },
}
