import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosMenuTagScreen = {
  POS_MENU_TAG: {
    PATH: '/master-data/menu-tag',
    TITLE: 'Menu Tag',
    TITLE_ALT: 'Menu Tag',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_TAG_ADD: {
    PATH: '/master-data/menu-tag/add',
    TITLE: 'Add Menu Tag',
    TITLE_ALT: 'Add Menu Tag',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_TAG_EDIT: {
    PATH: '/master-data/menu-tag/edit/:id',
    TITLE: 'Edit Menu Tag',
    TITLE_ALT: 'Edit Menu Tag',
  },
  POS_MENU_TAG_DETAIL: {
    PATH: '/master-data/menu-tag/detail/:id',
    TITLE: 'Edit Menu Tag',
    TITLE_ALT: 'Edit Menu Tag',
  },
}
